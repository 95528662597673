import React from "react";
import "../css/about.css";
import office2 from "../assets/images/office (2).jpeg";
import office3 from "../assets/images/office (3).jpeg";
import office4 from "../assets/images/office (4).jpeg";
import office5 from "../assets/images/office (5).jpeg";
import office7 from "../assets/images/office (7).jpeg";
import office8 from "../assets/images/office (8).jpeg";
import office9 from "../assets/images/office (9).jpeg";
export default function About() {
  return (
    <>
      <div className="about-div">
        <h1>About eWorx International</h1>
        <div className="about-text">
          <p>
            eWorx provides a vast array of technical solutions to the most
            challenging business problems our customers face. Whether your
            business needs world class talent to develop mission critical
            software applications, project management expertise to bring
            projects to fruition, digital marketing services, accounting
            services, medical transcription and medical billing services and
            last but not least we supply a wide variety of skilled staff
            augmentation services, eWorx can provide excellent highly skilled
            cost effective resources for fulfilling your business needs and
            requirements.
            <br />
            <br />
            eWorx was founded in
            <span className="blue">
              <b> 1997 </b>
            </span>
            in Lahore Pakistan. Eworx is over
            <span className="blue">
              <b> 2 decades </b>
            </span>
            old premier IT enabled services company serving the local as well as
            international markets in the English speaking world. Even though we
            very happily work with very large companies also but we take pride
            and great satisfaction in working with small startups and SME sector
            companies the most since these small , very young vulnerable
            companies need our cost effective business management solutions the
            most in order to survive on very tight budgets.
          </p>
        </div>
        <div className="why-eworx">
          <div>
            <h1>Why eWorx ?</h1>
            <div className="best-in">
              <div>
                <p>Website Development Services</p>
              </div>
              <div>
                <p>Digital Marketing Services</p>
              </div>
              <div>
                <p>Accounting and Tax Advisory Services</p>
              </div>
              <div>
                <p>Staff Augmentation all types</p>
              </div>
              <div>
                <p>Medical transcription and billing Services</p>
              </div>
              <div>
                <p>Software Development Services</p>
              </div>
              <div>
                <p>Legal Transcription</p>
              </div>
            </div>
          </div>
        </div>
        <div className="image-slider reveal">
          <h1>Infrastructure</h1>
          <div className="slider1">
            <img src={office5} alt="office" />
            <img src={office2} alt="office" />
            <img src={office9} alt="office" />
          </div>
          <h1>Our dedicated employees</h1>
          <div className="slider2 reveal">
            <img src={office7} alt="office" />
            <img src={office3} alt="office" />
            <img src={office4} alt="office" />
            <img src={office8} alt="office" />
          </div>
        </div>
        <div className="vision reveal">
          <div className="vission-text">
            <h2>Our Vision</h2>

            <p>
              Eworx vision is to generate regular employment for highly skilled
              and wide variety of talent pool available to us internationally. Eworx
              goal is to productively utilize them in delivering innovative and
              cost effective solutions for our local and global customers
              complex business and organizational needs and requirements using
              modern ICT tools and technologies.
            </p>
          </div>
        </div>
        <div className="mission-box reveal">
          <div className="mission ">
            <h2>Our Mission Statement</h2>
            <p>
              eWorx mission is to give effort on regular basis in following
              areas:
              <ul>
                <li>
                  <span className="arrow">&#10148; </span>
                  Being customer focused. Startups and SME'S are given same
                  value, care and attention as very large customer
                  organizations.
                </li>
                <li>
                  <span className="arrow">&#10148; </span>Producing high quality
                  products and services
                </li>
                <li>
                  <span className="arrow">&#10148; </span>Giving on time
                  delivery
                </li>
                <li>
                  <span className="arrow">&#10148; </span>Providing innovative
                  solutions
                </li>
                <li>
                  <span className="arrow">&#10148; </span>Providing cost
                  effective solutions
                </li>
              </ul>{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
