import "./App.css";
import React, { useEffect } from "react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import FAQs from "./components/FAQs";
import About from "./components/About";
import Career from "./components/Career";
import Contact from "./components/Contact";
import SuccessStories from "./components/SuccessStories";
import Services from "./components/services/services";
import Accounting from "./components/services/Accounting";
import DigitalMarket from "./components/services/DigitalMarket";
import Medical from "./components/services/Medical";
import RemoteStaff from "./components/services/RemoteStaff";
import WebDev from "./components/services/webDev";
import Privacy from "./components/Privacy";
import SoftwareDev from "./components/services/SoftwareDev";

function App() {
  
  const reveal = () => {
    var reveals = document.querySelectorAll(".reveal");
    for (let i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 80;
      if (revealTop < windowHeight - revealPoint) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  };

  window.addEventListener("scroll", reveal);
  useEffect(() => {
    const redirectUrl = "https://eworx.ridabaig.com";

    if (PerformanceNavigationTiming.type === 1) {
      window.location.replace(redirectUrl);
    }
  }, []);

  return (
    <>
      <Router>
        <header>
          <Navbar />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/career" element={<Career />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/Accounting" element={<Accounting />}></Route>
            <Route path="/digitalMarket" element={<DigitalMarket />}></Route>
            <Route path="/medical" element={<Medical />}></Route>
            <Route path="/remoteStaff" element={<RemoteStaff />}></Route>
            <Route path="/softwareDev" element={<SoftwareDev />}></Route>
            <Route path="/webDev" element={<WebDev />}></Route>
            <Route path="/faqs" element={<FAQs />}></Route>
            <Route path="/successstories" element={<SuccessStories />}></Route>
            <Route path="/privacypolicy" element={<Privacy />}></Route>
          </Routes>
        </main>

        <footer>
          <Footer />
        </footer>

      </Router>
    </>
  );
}

export default App;
