import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import image from "../assets/images/logo.png";
import "../css/style.css";
import "../css/mediaquery.css";

export default function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <header>
        <nav class="navbar navbar-expand-lg bg-body-tertiary" >
          <div class="container-fluid" >
            <Link to="/">
              <img
                src={image}
                alt="logo"
                className="logo"
                title="eWorx International Limited"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={toggleDropdown}
            >
              <span class="navbar-toggler-icon"></span>
            </button>{" "}
            <div
              className={`collapse navbar-collapse ${
                isDropdownOpen ? "show" : ""
              }`}
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown" onClick={closeDropdown}>
                  <Link class="nav-link " aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                <li class="nav-item"  onClick={closeDropdown}>
                  <Link class="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li class="nav-item dropdown">
                  <Link
                    class="nav-link dropdown-toggle"
                    to="/remoteStaff"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Services
                  </Link>
                  <ul class="dropdown-menu"  onClick={closeDropdown}>
                    <li>
                      <Link
                        class="dropdown-item nav-link-services"
                        to="/webDev"
                      >
                        Website Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item nav-link-services"
                        to="/digitalMarket"
                      >
                        Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item nav-link-services"
                        to="/Accounting"
                      >
                        Account and Tax Advisory
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item nav-link-services"
                        to="/remoteStaff"
                      >
                        Remote Staffing Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item nav-link-services "
                        to="/medical"
                      >
                        Medical Transcription and Billing
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item nav-link-services"
                        to="/softwareDev"
                      >
                        Software Development
                      </Link>
                    </li>
                  </ul>
                </li>
                <li class="nav-item"  onClick={closeDropdown}>
                  <Link class="nav-link" to="/successstories">
                    Clients
                  </Link>
                </li>
                <li class="nav-item"  onClick={closeDropdown}>
                  <Link class="nav-link" to="/faqs">
                    FAQ
                  </Link>
                </li>
                <li class="nav-item"  onClick={closeDropdown}>
                  <Link class="nav-link" to="/career">
                    Careers
                  </Link>
                </li>
                <li class="nav-item"  onClick={closeDropdown}>
                  <Link class="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
              <div class="d-flex">
                <Link to="/contact" class="btn-contact">
                  Get in touch
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
